/* modifications de minima */
.site-header {
  border-top: 0px;
  border-bottom: 1px solid #e8e8e8;
}

/* 404 page */
.container {
  margin: 10px auto;
  max-width: 600px;
  text-align: center;
}

h1.bigtitle {
  margin: 30px 0;
  font-size: 4em;
  line-height: 1;
  letter-spacing: -1px;
}

/* biographies */
.media, .media-body {
  overflow: hidden;
  zoom: 1;
}
.media {
  margin-top: 15px;
}
.media-body, .media-left, .media-right {
  display: table-cell;
  vertical-align: top;
}
.media-left, .media>.pull-left {
  padding-right: 10px;
}
.media-left > img {
  border-radius: 50%;
  width: 72px;
}
